import { Alert, Button, FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import { AddDomainResponseType } from '../../types/AddDomains';
import { DataContext } from '../../contexts/DataContext';
import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../components/@extended/AnimateButton';
import ResponseTable from '../../components/tables/ResponseTable';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link } from 'react-router-dom';

type ConfigType = {
  [key: string]: number | boolean;
};

export enum ConfigsEnum {
  HIGH_PRIORITY = 'highPriority',
  SCORE = 'score'
}

type AddDomainsContentProps = {
  registryId: string;
  registryName: string;
  url: string;
  config?: ConfigsEnum;
};
const AddDomainsContent = (props: AddDomainsContentProps) => {
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [isResponseVisible, setIsResponseVisible] = useState<boolean>(false);
  const [domainsList, setDomainsList] = useState<string[]>([]);
  const [invalidDomainsList, setInvalidDomainsList] = useState<AddDomainResponseType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { postData } = useContext(DataContext);

  const handleOnDomainsListChange = (data: any) => {
    const invalidDomainsListTemp: AddDomainResponseType[] = [];
    const validDomainsListTemp: string[] = [];
    const domainRegex = new RegExp('^([^\\.]{1,63})(\\.[^\\.\\.]{2,63})+$');

    let domainNames = data.trim().split(/\n/);
    domainNames.forEach((domain: string) => {
      const cleanedDomain = domain.trim().toLocaleLowerCase().replace(/\s+/g, '');
      if (domainRegex.test(cleanedDomain)) {
        validDomainsListTemp.push(cleanedDomain);
      } else if (cleanedDomain) {
        invalidDomainsListTemp.push({ name: cleanedDomain, status: 'Invalid domain' });
      }
    });

    setDomainsList(validDomainsListTemp);
    setInvalidDomainsList(invalidDomainsListTemp);
    setIsSubmitDisable(!(validDomainsListTemp.length > 0));
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    let response: any;

    try {
      const payload: { domain: string; configs?: ConfigType }[] = [];

      for (const domainData of domainsList) {
        const domainConfig: ConfigType = {};
        let domainName: string = domainData;

        switch (props.config) {
          case ConfigsEnum.SCORE:
            const domainDataList = domainData.trim().split(',');
            domainConfig[props.config as string] = domainDataList.length > 1 ? (domainDataList[1] ? parseInt(domainDataList[1]) : 0) : 0;
            domainName = domainDataList[0];
            break;
          case ConfigsEnum.HIGH_PRIORITY:
            domainConfig[props.config as string] = false;
            break;
        }

        if (Object.keys(domainConfig).length === 0) {
          payload.push({ domain: domainName });
        } else {
          payload.push({ domain: domainName, configs: domainConfig });
        }
      }

      if (postData) {
        response = await postData(props.url, { data: payload });
        setInvalidDomainsList(invalidDomainsList.concat(response?.data ?? []));
      }
    } catch (error: any) {
      console.log('Domain Submission Encountered an Issue', error);
    } finally {
      setIsLoading(false);
      setIsResponseVisible(true);
    }
  };

  const handleOnCancel = () => {
    setIsResponseVisible(false);
    setIsSubmitDisable(true);
  };

  if (isResponseVisible) {
    return (
      <Grid container>
        <Grid item xs={12} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <Alert style={{ marginBottom: '15px' }} icon={<InfoOutlinedIcon fontSize="inherit" />} severity="success">
            Domains will be processed and saved shortly. Check the{' '}
            <Link to="/submissionHistory" style={{ fontWeight: 600, color: 'inherit' }}>
              Submission History
            </Link>{' '}
            page for updates.
          </Alert>
          <ResponseTable responseData={invalidDomainsList} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Stack direction="row" justifyContent="flex-end">
            <AnimateButton>
              <Button color="primary" variant="contained" onClick={handleOnCancel}>
                Done
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12}>
          <InputLabel>Domains</InputLabel>
          <TextField
            fullWidth
            minRows={10}
            maxRows={20}
            multiline
            placeholder="Ex: example-domain.tld"
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleOnDomainsListChange(event.target.value)}
          />
          <FormHelperText>add domains list here</FormHelperText>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <AnimateButton>
              <LoadingButton variant="contained" type="submit" loading={isLoading} disabled={isSubmitDisable} onClick={handleOnSubmit}>
                Submit
              </LoadingButton>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    );
  }
};
export default AddDomainsContent;

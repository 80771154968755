import { ButtonBase, Chip, Grid, Tooltip } from '@mui/material';
import { AlignOptions, DateEnum, StatusColorEnum, StatusEnum, SuccessInformEnum } from '../../enums/Common';
import { AttemptColorEnum, ColumnType } from '../../types/FilteredDomain';
import { DateTime, Duration } from 'luxon';
import { useState } from 'react';
import SuccessInformPopup from '../../components/popup/SuccessInformPopup';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import AbInformCell from './AbInformCell';

const iconBaseStyle = { fontSize: '14px', marginTop: '2px' };

export const domainNameCell = (name: string, isInformedFromAB: boolean) => {
  return (
    <Grid container columnSpacing={2} wrap="nowrap">
      <Grid item xs={2} display={'flex'} alignItems={'center'}>
        <Tooltip disableFocusListener title={isInformedFromAB ? 'Informed from AB' : 'Not Informed from AB'}>
          <ButtonBase>
            {(() => {
              switch (isInformedFromAB) {
                case false:
                  return <DonutLargeOutlinedIcon style={{ ...iconBaseStyle, color: '#67bf6b' }} />;
                case true:
                  return <ArrowCircleRightOutlinedIcon style={{ ...iconBaseStyle, color: '#1890ff' }} />;
              }
            })()}
          </ButtonBase>
        </Tooltip>
      </Grid>
      <Grid item xs={10} display={'flex'} alignItems={'center'}>
        {name}
      </Grid>
    </Grid>
  );
};

export const priorityCell = (value: any) => {
  if (value) {
    return <Chip color="error" label="High" size="small" variant="light" />;
  } else {
    return <Chip color="info" label="Low" size="small" variant="light" />;
  }
};

export const statusCell = (value: any) => {
  if (value) {
    let label = Object.keys(StatusEnum)[Object.values(StatusEnum).indexOf(value)];
    label = label.replace(/([A-Z])/g, ' $1');
    label = label.charAt(0).toUpperCase() + label.slice(1);
    // @ts-ignore
    const color = StatusColorEnum[Object.keys(StatusColorEnum)[Object.values(StatusEnum).indexOf(value)]];
    return <Chip label={label} size="small" variant="light" style={{ backgroundColor: color + '40', color: color }} />;
  } else {
    return '';
  }
};

export const getClickableStyle = (isValid: boolean, color: string) => {
  return {
    backgroundColor: `${color}30`,
    width: StatusColorEnum.NotProcessed === color ? '100px' : undefined,
    color: color,
    cursor: isValid ? 'pointer' : 'default',
    borderColor: color,
    transition: 'transform 1s ease, box-shadow 0.2s ease',
    '&:hover': {
      backgroundColor: isValid ? `white !important` : `${color}30`,
      ...(isValid && {
        transform: 'scale(1.02)',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)'
      })
    }
  };
};

export const successInformCell = (status: any, isSuccessInformed: any, domainName: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isInformed, setIsInformed] = useState(isSuccessInformed);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isSuccessInformPopupOpen, setIsSuccessInformPopupOpen] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const handleInformedSuccessfully = () => {
    setIsInformed(true);
  };
  const handleInformedFailed = () => {
    setIsInformed(false);
  };

  const handlePopupClose = () => {
    setIsSuccessInformPopupOpen(false);
  };
  const handleChipClick = async () => {
    setIsSuccessInformPopupOpen(true);
  };

  let label, color;
  if (status === StatusEnum.Success) {
    if (isInformed) {
      label = SuccessInformEnum.INFORMED;
      // @ts-ignore
      color = StatusColorEnum[Object.keys(StatusColorEnum)[Object.values(StatusEnum).indexOf(StatusEnum.Success)]];
    } else {
      label = SuccessInformEnum.FAILED;
      // @ts-ignore
      color = StatusColorEnum[Object.keys(StatusColorEnum)[Object.values(StatusEnum).indexOf(StatusEnum.Failure)]];
    }
  } else {
    label = 'Not Informed';
    // @ts-ignore
    color = StatusColorEnum[Object.keys(StatusColorEnum)[Object.values(StatusEnum).indexOf(StatusEnum.NotProcessed)]];
  }
  return (
    <div>
      <Chip
        label={isHovering ? 'Inform now' : label}
        onClick={!isInformed && status === StatusEnum.Success ? handleChipClick : undefined}
        size="small"
        variant={!isInformed && status === StatusEnum.Success ? 'outlined' : 'light'}
        sx={getClickableStyle(!isInformed && status === StatusEnum.Success, color)}
        onMouseEnter={() => {
          if (!isInformed && status === StatusEnum.Success) {
            setIsHovering(true);
          }
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      />
      <SuccessInformPopup
        isSuccessInformPopupOpen={isSuccessInformPopupOpen}
        domain={domainName}
        handleInformedSuccessfully={handleInformedSuccessfully}
        handleInformedFailed={handleInformedFailed}
        handlePopupClose={handlePopupClose}
      />
    </div>
  );
};

export const attemptCell = (status: string, isFirstAttempt: boolean, isSecondAttempt: boolean) => {
  if (status === StatusEnum.Success || status === StatusEnum.Failure) {
    if (status === StatusEnum.Success && isFirstAttempt && isSecondAttempt) {
      return <Chip color={AttemptColorEnum.IncorrectPrediction} label={'Incorrect Prediction'} size="small" variant="light" />;
    } else if (isFirstAttempt && !isSecondAttempt) {
      return <Chip color={AttemptColorEnum.FirstAttempt} label={'First Attempt'} size="small" variant="light" />;
    } else if (status === StatusEnum.Failure && isFirstAttempt && isSecondAttempt) {
      return <Chip color={AttemptColorEnum.FirstAttempt} label={'First Attempt'} size="small" variant="light" />;
    } else if (!isFirstAttempt && isSecondAttempt) {
      return <Chip color={AttemptColorEnum.SecondAttempt} label={'Second Attempt'} size="small" variant="light" />;
    } else if (status !== StatusEnum.Success && !isFirstAttempt && !isSecondAttempt) {
      return <Chip color={AttemptColorEnum.NotAttempt} label={'Not Attempt'} size="small" variant="light" />;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const dateTimeFormat = (value: any) => {
  if (typeof value === 'string') {
    value = parseInt(value);
  }
  if (!isNaN(value) && value !== null) {
    return DateTime.fromMillis(value / 1000).toFormat(DateEnum.DateTimeFormatLuxon);
  } else {
    return '';
  }
};

export const millsTimeFormat = (value: any) => {
  if (!isNaN(value) && value !== null) {
    return DateTime.fromMillis(convertEpoch(value, 'milliseconds')).toFormat('TT:SSS');
  } else {
    return '';
  }
};

export const formatDropDate = (value: any) => {
  if (typeof value === 'string') {
    value = parseInt(value);
  }
  if (value) {
    if (!isNaN(value)) {
      return DateTime.fromMillis(value / 1000).toFormat('y-LL-dd');
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const calculateDelay = (dropDelay: number) => {
  if (dropDelay) {
    if (!isNaN(dropDelay)) {
      return 'Delayed By ' + Duration.fromMillis(dropDelay).toFormat('hh:mm:ss');
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const dropWindowCell = (withinDropWindow: any, dropTime: any, droppedAt: any) => {
  if (withinDropWindow) {
    if (withinDropWindow === 'No') {
      if (!droppedAt || !dropTime) {
        return '';
      }
      const dropDelay = convertEpoch(droppedAt, 'milliseconds') - convertEpoch(dropTime, 'milliseconds');
      return calculateDelay(dropDelay);
    } else {
      return withinDropWindow;
    }
  } else {
    return '';
  }
};

export const createDelayCell = (data: object) => {
  if (data.hasOwnProperty('applicationCreateTime')) {
    // @ts-ignore
    if (data.applicationCreateTime && data.dropTime) {
      // @ts-ignore
      const dropDelay = convertEpoch(data.applicationCreateTime, 'milliseconds') - convertEpoch(data.dropTime, 'milliseconds');
      return '' + dropDelay;
    } else {
      return '';
    }
  } else {
    // @ts-ignore
    if (data.droppedAt && data.dropTime) {
      // @ts-ignore
      const dropDelay = convertEpoch(data.droppedAt, 'milliseconds') - convertEpoch(data.dropTime, 'milliseconds');
      return '' + dropDelay;
    } else {
      return '';
    }
  }
};

export const roundTripCell = (sentAtEpoch: number, receivedAtEpoch: number) => {
  if (sentAtEpoch && receivedAtEpoch) {
    return convertEpoch(receivedAtEpoch, 'milliseconds') - convertEpoch(sentAtEpoch, 'milliseconds');
  } else {
    return '';
  }
};

function convertEpoch(epoch: number, convertTo: string): number {
  if (epoch) {
    const epochLen = epoch.toString().length;
    const secondsLen = Math.floor(Date.now() / 1000).toString().length;
    switch (convertTo) {
      case 'seconds': {
        const diff = epochLen - secondsLen;
        if (diff === 0) {
          return epoch;
        } else if (diff > 0) {
          return epoch / 10 ** diff;
        } else {
          return epoch * 10 ** -diff;
        }
      }
      case 'milliseconds': {
        const diff = epochLen - (secondsLen + 3);
        if (diff === 0) {
          return epoch;
        } else if (diff > 0) {
          return epoch / 10 ** diff;
        } else {
          return epoch * 10 ** -diff;
        }
      }
      case 'microseconds': {
        const diff = epochLen - (secondsLen + 6);
        if (diff === 0) {
          return epoch;
        } else if (diff > 0) {
          return epoch / 10 ** diff;
        } else {
          return epoch * 10 ** -diff;
        }
      }
      case 'nanoseconds': {
        const diff = epochLen - (secondsLen + 9);
        if (diff === 0) {
          return epoch;
        } else if (diff > 0) {
          return epoch / 10 ** diff;
        } else {
          return epoch * 10 ** -diff;
        }
      }
    }
  }
  return epoch;
}

export const allColumnsIDs: string[] = [
  'name',
  'highPriority',
  'dropTimeFromFTP',
  'updatedDateFromWHOIS',
  'start-time',
  'droppedAt',
  'dropDelay',
  'status',
  'registeredTo',
  'drop-window',
  'drop-date',
  'create-time',
  'createCount',
  'attempt',
  'sentAtEpoch',
  'createDelay',
  'roundTrip',
  'successAttempt',
  'worker',
  'dropTimeNominet',
  'drop-time-identityDigital',
  'success-inform',
  'ab-inform'
];
export const tableColumns: ColumnType[] = [
  //belong registry - all
  {
    Header: 'Domain',
    accessor: 'name',
    id: 'name',
    align: AlignOptions.CENTER,
    Cell: ({ row }: any) => domainNameCell(row.original.name, row.original.isInformedFromAB)
  },
  //belong registry - CentralNic
  {
    Header: 'Priority',
    accessor: 'highPriority',
    id: 'highPriority',
    disableSortBy: true,
    align: AlignOptions.CENTER,
    Cell: ({ value }: any) => priorityCell(value)
  },
  //belong registry - CentralNic
  {
    Header: 'FTP Time',
    accessor: 'dropTimeFromFTP',
    id: 'dropTimeFromFTP',
    disableSortBy: true,
    type: 'dateTime',
    Cell: ({ value }: any) => dateTimeFormat(value)
  },
  //belong registry - CentralNic
  {
    Header: 'Updated Time',
    accessor: 'updatedDateFromWHOIS',
    id: 'updatedDateFromWHOIS',
    type: 'dateTime',
    disableSortBy: true,
    Cell: ({ value }: any) => dateTimeFormat(value)
  },
  //belong registry - CentralNic, SIDN, RoTLD
  {
    Header: 'Start Time',
    accessor: 'dropTime',
    id: 'dropTime',
    type: 'dateTime',
    Cell: ({ value }: any) => dateTimeFormat(value)
  },
  //belong registry - Verisign, identity digital
  {
    Header: 'Drop Date',
    accessor: 'dropTime',
    id: 'drop-date',
    disableSortBy: true,
    type: 'dateTime',
    pattern: 'y-LL-dd',
    Cell: ({ value }: any) => formatDropDate(value)
  },
  //belong registry - CentralNic, SIDN, rotld
  {
    Header: 'Drop Time',
    accessor: 'droppedAt',
    id: 'droppedAt',
    disableSortBy: true,
    type: 'dateTime',
    Cell: ({ value }: any) => dateTimeFormat(value)
  },
  //belong registry - Nominet
  {
    Header: 'Drop Time',
    accessor: 'dropTime',
    id: 'dropTimeNominet',
    disableSortBy: true,
    type: 'dateTime',
    Cell: ({ value }: any) => dateTimeFormat(value)
  },
  //belong registry - SIDN
  {
    Header: 'Drop Delay',
    accessor: 'dropDelay',
    id: 'dropDelay',
    disableSortBy: true,
    Cell: ({ value }: any) => calculateDelay(value)
  },
  //belong registry - identity digital
  {
    Header: 'Sent Time',
    accessor: 'sentAtEpoch',
    id: 'sentAtEpoch',
    disableSortBy: true,
    type: 'milliseconds',
    Cell: ({ value }: any) => millsTimeFormat(value)
  },
  //belong registry - Identity Digital
  {
    Header: 'Create Delay (ms)',
    id: 'createDelay',
    disableSortBy: true,
    Cell: ({ row }: any) => createDelayCell(row.original)
  },
  //belong registry - Identity Digital
  {
    Header: 'Round Trip (ms)',
    id: 'roundTrip',
    disableSortBy: true,
    Cell: ({ row }: any) => roundTripCell(row.original.sentAtEpoch, row.original.receiveAtEpoch)
  },
  //belong registry - identity digital, Nominet
  {
    Header: 'Success Attempt',
    accessor: 'successAttempt',
    id: 'successAttempt',
    disableSortBy: true
  },
  //belong registry - identity digital
  {
    Header: 'Worker',
    accessor: 'worker',
    id: 'worker',
    disableSortBy: true
  },
  //belong registry - identity digital
  {
    Header: 'Drop Time',
    accessor: 'droppedAt',
    id: 'drop-time-identityDigital',
    disableSortBy: true,
    type: 'dateTime',
    Cell: ({ value }: any) => dateTimeFormat(value)
  },
  //belong registry - verisign
  {
    Header: 'Create Time',
    accessor: 'droppedAt',
    id: 'create-time',
    disableSortBy: true,
    type: 'dateTime',
    Cell: ({ value }: any) => dateTimeFormat(value)
  },
  //belong registry - Verisign
  {
    Header: 'Create Count',
    accessor: 'commandCount',
    id: 'createCount',
    disableSortBy: true
  },
  //belong registry - all
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    disableSortBy: true,
    align: AlignOptions.CENTER,
    Cell: ({ value }: any) => statusCell(value)
  },
  //belong registry - verisign
  {
    Header: 'Attempt',
    accessor: 'attempt',
    id: 'attempt',
    disableSortBy: true,
    align: AlignOptions.CENTER,
    Cell: ({ row }: any) => attemptCell(row.original.status, row.original.isFirstAttempt, row.original.isSecondAttempt)
  },
  //belong registry - all
  {
    Header: 'Registrar',
    accessor: 'registeredTo',
    id: 'registeredTo',
    disableSortBy: true
  },
  //belong registry - CentralNic
  {
    Header: 'Drop Window',
    id: 'drop-window',
    disableSortBy: true,
    align: AlignOptions.CENTER,
    Cell: ({ row }: any) => dropWindowCell(row.original.withinDropWindow, row.original.dropTime, row.original.droppedAt)
  },
  //belong registry - all
  {
    Header: 'Success Inform',
    accessor: 'isSuccessInformed',
    id: 'success-inform',
    disableSortBy: true,
    align: AlignOptions.CENTER,
    Cell: ({ row }: any) => successInformCell(row.original.status, row.original.isSuccessInformed, row.original.name)
  },
  //belong registry - all
  {
    Header: 'AB Success Inform',
    accessor: 'isInformedStatusToAB',
    id: 'ab-inform',
    disableSortBy: true,
    align: AlignOptions.CENTER,
    Cell: ({ row }: any) => (
      <AbInformCell
        domainName={row.original.name}
        domainStatus={row.original.status}
        isInformedFromAB={row.original.isInformedFromAB}
        isInformedStatusToAB={row.original.isInformedStatusToAB}
      />
    )
  }
];
